import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { setDateRange, fetchAnalyticsData } from '../../store/features/analytics/analyticsAction';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Switch } from "../../components/ui/switch";
import { useMediaQuery } from 'react-responsive';
import { BASE_URL } from '../../api/API';

const BarChartComp = () => {
  const [data, setData] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState("impressions");

  const { userId, dateRange } = useSelector((state) => state.analytics);
  const { isDark } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 1188px)" });

  useEffect(() => {
    if (userId && dateRange) {
      const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
      const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);

      const fetchData = async () => {
        try {
          const url = `${BASE_URL}analytics/graphs/all-data?userId=${userId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
          const response = await fetch(url);
          const result = await response.json();

          // Format data based on selected metric
          const formattedData = result.map((item) => ({
            name: item.name,
            value: item.value[selectedMetric],
          }));

          setData(formattedData);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };

      fetchData();
    }
  }, [userId, dateRange, selectedMetric]);

  // Check if all values are zero
  const allValuesZero = data.every((entry) => entry.value === 0);

  const maxValue = Math.max(...data.map((entry) => entry.value), 0);
  const yAxisDomain = [0, maxValue + 10];

  return (
    <div className="flex flex-col items-center gap-4">
    <div
  className={`flex w-full items-center justify-between pr-4 py-2 ${
    !isDark ? "text-white bg-black-900" : "text-black bg-white-100"
  }`}
  style={{ borderRadius: "8px" }}
>
  <h3
    className={`text-2xl font-bold ${
      !isDark ? "text-white" : "text-black"
    }`}
    style={{ marginLeft: 0 }}
  >
    Overall Stats Tracking
  </h3>
  <select
    onChange={(e) => setSelectedMetric(e.target.value)}
    value={selectedMetric}
    className={`p-2 border rounded ${
      !isDark ? "bg-gray-700 text-white" : "bg-white text-black"
    }`}
  >
    <option value="impressions">Impressions</option>
    <option value="engagements">Engagements</option>
    <option value="reactions">Reactions</option>
    <option value="comments">Comments</option>
    <option value="followers">Followers</option>
  </select>
</div>
      <div
        className={`chart-box shadow-md p-4 w-full ${
          !isDark ? "bg-[#2D2D2D] text-white" : "bg-gray-50 text-black"
        }`}
        style={{ borderRadius: "8px" }}
      >
        {data.length === 0 || allValuesZero ? (
          <div className="flex items-center justify-center h-96">
            <p
              className={`text-lg font-semibold ${
                !isDark ? "text-gray-400" : "text-gray-600"
              }`}
            >
              No data available to display. Please adjust your filters or try again later.
            </p>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
              <defs>
                <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#8A51FF" stopOpacity={0.85} />
                  <stop offset="100%" stopColor="#39B2FF" stopOpacity={0.85} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                axisLine={{ stroke: "#CCC" }}
                tickLine={false}
                tick={{
                  fill: isDark ? "black" : "white",
                  fontWeight: "bold",
                }}
              />
              <YAxis
                domain={yAxisDomain}
                axisLine={{ stroke: "#CCC" }}
                tickLine={false}
                tickFormatter={(value) => value.toLocaleString()}
                tick={{
                  fill: isDark ? "black" : "white",
                  fontWeight: "bold",
                }}
              />
              <CartesianGrid
                horizontal={true}
                vertical={false}
                strokeDasharray="3 3"
                stroke="#DDD"
              />
              <Bar
                dataKey="value"
                radius={[10, 10, 0, 0]}
                fill="url(#barGradient)"
                maxBarSize={80}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default BarChartComp;