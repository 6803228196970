import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  allComments,
  postAnalytics,
  reactionSummary,
  userProfileData,
} from "../../store/features/analytics/analyticsAction";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IMAGE_URL, apibase } from "../../api/API";

function PostAnalytics() {
  const { isDark } = useSelector((state) => state.common);
  const { userData } = useSelector((state) => state.user);
  console.log("userData");
  console.log(userData);
  console.log("userData");
  useEffect(() => {
    dispatch(postAnalytics(postId));
  }, []);

  const {
    likesByCompany,
    likesByOccupation,
    likesByRegion,
    commentsByCompany,
    commentsByOccupation,
    commentsByRegion,
    postPost,
  } = useSelector((s) => s.analytics);
  console.log(postPost);
  const calculateRegionData = (likesByRegion, commentsByRegion) => {
    const regionData = {};

    // Check if likesByRegion and commentsByRegion are defined
    if (likesByRegion) {
      Object.entries(likesByRegion).forEach(([region, likes]) => {
        regionData[region] = (regionData[region] || 0) + likes;
      });
    }

    if (commentsByRegion) {
      Object.entries(commentsByRegion).forEach(([region, comments]) => {
        regionData[region] = (regionData[region] || 0) + comments;
      });
    }

    // Calculate total count
    const totalCount = Object.values(regionData).reduce(
      (sum, count) => sum + count,
      0
    );

    // Calculate percentage for each region and format the data
    const data = Object.entries(regionData).map(([region, count]) => ({
      name: region,
      value: Math.round((count / totalCount) * 100),
    }));

    return data;
  };

  const data1 = calculateRegionData(likesByRegion, commentsByRegion);
  const data2 = calculateRegionData(likesByOccupation, commentsByOccupation);
  const data3 = calculateRegionData(likesByCompany, commentsByCompany);

  const navigate = useNavigate();
  const location = useLocation();
  const postId = location.state?.postId;
  console.log(postId);
  const { post,perPostAnalytics } = useSelector((s) => s.analytics);
  // const { performance } = perPostAnalytics || {};
  const { impressions, membersReached, reactions, comments,reposts } = perPostAnalytics?.performance || {};

  console.log("perPostAnalytics")
  console.log(perPostAnalytics)
  console.log("perPostAnalytics")
    const analyticsState = useSelector((state) => state.analytics);

  const [activePost, setActivePost] = useState();
  useEffect(() => {
    if (Array.isArray(post) && post.length > 0) {
      const obj = post.find((p) => p.postId === postId);
      setActivePost(obj);
    }
  }, [postId, post]);
  console.log(postId);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userProfileData(userData.linkedinId));
    dispatch(reactionSummary(postId));
    dispatch(allComments(postId));
  }, []);
  const analyticsData = useSelector((s) => s.analytics);
  console.log("analyticsData");
  console.log(analyticsData);
  console.log("analyticsData");
  const image = "/images/pp.jpeg";
  return (
    <>
      <div className="flex flex-col gap-4 px-4 py-4 ">
        {/* <span className={`${isDark?'bg-white':'bg-[#2D2D2D]'} w-[196px] text-center p-2 rounded-lg cursor-pointer`} onClick={()=>navigate('/analytics')}>&larr; Go back to Analytics</span> */}
        <div className="flex justify-start mb-2">
        <button
          onClick={() => navigate('/analytics')}
          className={`px-4 py-2 rounded-lg text-base font-semibold transform transition-transform duration-200 ${
            !isDark
              ? "bg-gray-800 text-white hover:bg-gray-600"
              : "bg-white text-gray-800 hover:bg-gray-200"
          }`}
        >
          Back to Analytics
        </button>
      </div>

      {/* Main Content */}
      <div className="flex justify-evenly items-start max-md:flex-col max-md:mb-2 w-full">
      <div className="bg-white p-3 rounded-lg w-[50%] max-md:w-[100%] max-md:mb-3 flex flex-col gap-3">
                <div className="flex justify-between items-start">
              <div className="flex items-start gap-3">
                {analyticsData ? (
                  <img
                    src={
                      analyticsData.linkedinProfile
                        ? analyticsData.linkedinProfile
                        : image
                    }
                    className="w-12 h-12 rounded-full"
                  ></img>
                ) : (
                  ""
                )}
                <div className="flex flex-col gap-1">
                  {analyticsData ? (
                    <p style={{ color: "black" }} className="font-medium">
                      {userData.userName}
                    </p>
                  ) : (
                    ""
                  )}
                  {analyticsData ? (
                    <p style={{ color: "gray" }} className="text-sm">
                      {userData.localizedHeadline}
                    </p>
                  ) : (
                    ""
                  )}
                  {activePost ? (
                    <p
                      style={{ color: "gray" }}
                      className="text-sm flex items-center gap-2"
                    >
                      {activePost.postTimeAgo}{" "}
                      <i className="fa-solid fa-earth-europe"></i>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <img src="/images/linkedInIcon.svg"></img>
            </div>
            <div className="flex flex-col justify-center gap-1">
              {activePost ? (
                <>
                  <p style={{ color: "black" }}>
                    {activePost.postContent.length > 500
                      ? `${activePost.postContent.slice(0, 500)}...`
                      : activePost.postContent}
                    {activePost.postContent.length > 500 ? (
                      <a href={activePost.linkedinPostUrl} target="_blank">
                        <span
                          style={{ color: "#6f9cde" }}
                          className="cursor-pointer"
                        >
                          read more
                        </span>
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  {/* Add URL preview for post content */}
                  {extractUrl(activePost.postContent) && (
                    <UrlPreview url={extractUrl(activePost.postContent)} />
                  )}
                </>
              ) : (
                ""
              )}
              <div className="flex justify-center items-center">
                {postPost && postPost.postType == "IMAGE" ? (
                  <img
                    src={`https://growero-staging.s3.ap-south-1.amazonaws.com/uploads/${postPost.originalUrl}`}
                    className="w-96"
                  ></img>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-blue-500">
              {postPost && postPost.postType == "ARTICLE" ? (
                <UrlPreview url={postPost.originalUrl} />
              ) : (
                ""
              )}
            </div>
            <div className="flex gap-4">
              <div className="flex gap-1 items-center">
                <img src="/images/likeBlack.svg"></img>
                {activePost ? (
                  <p style={{ color: "black" }}>{activePost.likes}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="flex gap-1 items-center">
                <img src="/images/commentBlack.svg "></img>
                {activePost ? (
                  <p style={{ color: "black" }}>{activePost.comments}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
                    </div>
                    <div className="flex flex-col items-center gap-14 mb-6 mt-3 w-full">
  {/* First Row with 3 items */}
  <div className="flex flex-wrap justify-center gap-6">
    {[
      { title: "Reactions", value: reactions || "0" },
      { title: "Comments", value: comments || "0" },
      { title: "Members Reached", value: membersReached || "0" },
    ].map((item, index) => (
      <div
        key={index}
        className={`relative flex flex-col items-center justify-center p-6 shadow-md rounded-lg w-56 h-32 transform transition-transform duration-200 hover:scale-105 ${
          isDark ? "bg-white text-gray-800" : "bg-gray-800 text-white"
        }`}
      >
        <div
          className="absolute top-0 left-0 right-0 h-1 rounded-t-lg"
          style={{
            background: "linear-gradient(126deg, #8A51FF -9.26%, #39B2FF 79.86%)",
          }}
        />
        <h3 className="text-lg font-semibold mb-1 text-center">{item.title}</h3>
        <p
          className="text-2xl font-extrabold text-center leading-none"
          style={{
            color: isDark ? "#6610f2" : "#007bff",
          }}
        >
          {item.value}
        </p>
      </div>
    ))}
  </div>

  {/* Second Row with 2 items */}
  <div className="flex flex-wrap justify-center gap-6">
    {[
      { title: "Reposts", value: reposts || "0" },
      { title: "Impressions", value: impressions || "0" },
    ].map((item, index) => (
      <div
        key={index}
        className={`relative flex flex-col items-center justify-center p-6 shadow-md rounded-lg w-56 h-32 transform transition-transform duration-200 hover:scale-105 ${
          isDark ? "bg-white text-gray-800" : "bg-gray-800 text-white"
        }`}
      >
        <div
          className="absolute top-0 left-0 right-0 h-1 rounded-t-lg"
          style={{
            background: "linear-gradient(126deg, #8A51FF -9.26%, #39B2FF 79.86%)",
          }}
        />
        <h3 className="text-lg font-semibold mb-1 text-center">{item.title}</h3>
        <p
          className="text-2xl font-extrabold text-center leading-none"
          style={{
            color: isDark ? "#6610f2" : "#007bff",
          }}
        >
          {item.value}
        </p>
      </div>
    ))}
  </div>
</div>

</div>
        {/* <div className="flex gap-4"> */}
          <CompanySizeDistributionChart/>
        {/* </div> */}
        <IndustryDistributionChart/>
        <LikesByRegionChart/>
        <div className="flex gap-4 w-full max-md:flex-col">
          <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-start mb-4">
  <p className={`text-xl font-semibold ${!isDark ? "text-white" : "text-black"}`}>
    Comments
  </p>
</div>
            <div
              className={`${
                isDark ? "bg-white" : "bg-[#2D2D2D]"
              } p-3 flex flex-col rounded-lg gap-4 w-full min-h-56`}
            >
              {analyticsData.comments && analyticsData.comments.length > 0 ? (
                analyticsData.comments.map((comment) => (
                  <CommentComponent key={comment.name} comment={comment} />
                ))
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p className="text-center">No comment on this post yet</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-start mb-4">
  <p className={`text-xl font-semibold ${!isDark ? "text-white" : "text-black"}`}>
    Reactions
  </p>
</div>

  <div
    className={`${
      !isDark ? "bg-[#2D2D2D]" : "bg-white"
    } p-6 flex flex-col gap-6 justify-center items-center rounded-lg min-h-[300px] shadow-md max-md:min-h-[150px] transition-all duration-300`}
  >
    {analyticsData.reaction && typeof analyticsData.reaction === "object" ? (
      <div className="flex flex-wrap justify-center gap-6">
        {[
          { type: "LIKE", icon: "/images/linkedInLikes.png", label: "Like" },
          { type: "EMPATHY", icon: "/images/Love.png", label: "Empathy" },
          { type: "PRAISE", icon: "/images/Celebrate.png", label: "Praise" },
          { type: "APPRECIATION", icon: "/images/Support.png", label: "Appreciation" },
          { type: "INTEREST", icon: "/images/Insightful.png", label: "Insightful" },
          { type: "ENTERTAINMENT", icon: "/images/funny.png", label: "Entertainment" },
        ].map((reaction, index) => (
          <div
            key={index}
            className={`relative flex flex-col items-center justify-center p-6 shadow-md rounded-lg w-full sm:w-1/3 transform transition-transform duration-200 hover:scale-105 ${
              isDark ? "bg-white text-gray-800" : "bg-gray-800 text-white"
            }`}
          >
            <div
              className="absolute top-0 left-0 right-0 h-1 rounded-t-lg"
              style={{
                background: "linear-gradient(126deg, #8A51FF -9.26%, #39B2FF 79.86%)",
              }}
            />
            <img
              src={reaction.icon}
              alt={reaction.label}
              className="w-14 max-md:w-12 transition-transform duration-300 hover:scale-110"
            />
            <h3 className="text-xl font-semibold mb-2">{reaction.label}</h3>
            <p
              className={`text-3xl font-extrabold`}
              style={{
                color: isDark ? "#6610f2" : "#007bff", // Matching complementary text color
              }}
            >
              {analyticsData.reaction[reaction.type]
                ? analyticsData.reaction[reaction.type].count
                : 0}
            </p>
          </div>
        ))}
      </div>
    ) : (
      <p className="text-lg font-medium text-gray-500">No reaction data available</p>
    )}
  </div>
</div>
        </div>
        </div>
    </>
  );
}

import axios from "axios";
import CompanySizeDistributionChart from "./CompanySizeDistributionChart";
import IndustryDistributionChart from "./IndustryDistributionChart";
import LikesByRegionChart from "./LikesByRegionChart";

const image = "/images/pp.jpeg";
const UrlPreview = ({ url }) => {
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.post(`${apibase}/api/get-meta-data`, {
          url,
        });
        setPreviewData(response.data);
      } catch (error) {
        console.error("Error fetching metadata:", error);
        setPreviewData({
          data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
        });
      }
    };

    fetchMetaData();
  }, [url]);

  if (!previewData) return null;

  return (
    <div
      className="card mt-2"
      style={{ margin: "10px 0", position: "relative" }}
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "black" }}
      >
        {previewData.data &&
          previewData.data.ogImage &&
          previewData.data.ogImage[0] &&
          previewData.data.ogImage[0].url && (
            <img
              src={previewData.data.ogImage[0].url}
              className="card-img-top"
              style={{ margin: "5% 5% 5% 5%", height: "20%", width: "90%" }}
              alt="Preview Image"
            />
          )}
        <div
          style={{ backgroundColor: "rgb(237,243,248)" }}
          className="card-body"
        >
          <h6
            style={{ fontWeight: "bold", marginLeft: "16px" }}
            className="card-title text-dark ml-10"
          >
            {previewData.data.ogTitle}
          </h6>
          <p className="card-text" style={{ color: "black" }}>
            {previewData.data.ogDescription}
          </p>
        </div>
      </a>
    </div>
  );
};
const extractUrl = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const match = text.match(urlRegex);
  return match ? match[0] : null;
};

const defaultAvatar = "https://dev.growero.io/images/Icons/avatar.jpg";
const CommentComponent = ({ comment, defaultImage = defaultAvatar }) => {
  // const extractUrl = (text) => {
  //   const urlRegex = /(https?:\/\/[^\s]+)/g;
  //   const match = text.match(urlRegex);
  //   return match ? match[0] : null;
  // };

  const url = extractUrl(comment.comment);

  return (
    <div
    className="flex gap-3 items-center justify-center"  // Added justify-center
    key={`${comment.name}-${comment.time}`}
>
  <img
    src={comment.img ? comment.img : defaultImage}
    className="w-10 h-10 rounded-full"
    alt=""
  />
      <div className="flex flex-col gap-1 w-full">
        <div className="p-2 rounded-lg w-full">
          <span className="text-sm flex gap-2">
            {comment.name}
            <p style={{ color: "gray" }}>{comment.time}</p>
          </span>
          <span className="text-sm">{comment.comment}</span>

          {url && <UrlPreview url={url} />}

          {comment.media && (
            <div className="mt-2">
              {comment.media.type === "image" && (
                <img
                  src={comment.media.url}
                  alt="Attached image"
                  className="max-w-full h-auto rounded"
                />
              )}
              {comment.media.type === "file" && (
                <a
                  href={comment.media.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  <i className="fas fa-file mr-2"></i>
                  {comment.media.filename || "Attached file"}
                </a>
              )}
            </div>
          )}
        </div>

        {comment.commentsOnComment &&
          comment.commentsOnComment.map((reply) => (
            <CommentComponent
              key={`${reply.name}-${reply.time}`}
              comment={reply}
              defaultImage={defaultImage}
            />
          ))}
      </div>
    </div>
  );
};
export default PostAnalytics;