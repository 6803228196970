import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAnalyticsData, postAnalytics } from "../../store/features/analytics/analyticsAction";

function Table() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDark } = useSelector((state) => state.common);
  const { posts, userId, dateRange } = useSelector((state) => state.analytics);
  const { userData } = useSelector((state) => state.user);

  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [startDate, setStartDate] = useState(dateRange.startDate);
  const [endDate, setEndDate] = useState(dateRange.endDate);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  };

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchAnalyticsData(userId, startDate.toISOString(), endDate.toISOString())
      );
    }
  }, [userId, startDate, endDate, dispatch]);

  const sortPosts = (posts) => {
    if (Array.isArray(posts)) {
      return posts.sort((a, b) => {
        let comparison = 0;
        if (sortColumn === "createdAt") {
          const dateA = new Date(a[sortColumn]);
          const dateB = new Date(b[sortColumn]);
          comparison = sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        } else if (sortColumn === "reactions" || sortColumn === "comments" || sortColumn === "shares" || sortColumn === "impressions" || sortColumn === "engagement") {
          comparison =
            sortOrder === "asc"
              ? a[sortColumn] - b[sortColumn]
              : b[sortColumn] - a[sortColumn];
        }
        return comparison;
      });
    } else {
      return [];
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleDateChange = (type, date) => {
    if (type === "start") {
      setStartDate(new Date(date));
    } else {
      setEndDate(new Date(date));
    }
  };

  const calculateEngagementRate = (engagements, impressions) => {
    return impressions > 0 ? ((engagements / impressions) * 100).toFixed(2) : "0.00";
  };

  const sortedPosts = sortPosts(posts || []);

  return (
    <div className="table-responsive">
      <table
        className={`table table-hover ${
          !isDark ? "table-dark text-white" : "table-light text-dark"
        }`}
        style={{ textAlign: "center" }}
      >
        <thead>
          <tr>
            {[
              { title: "Profile" },
              { title: "Content" },
              { title: "Created", sortable: true, column: "createdAt" },
              { title: "Reactions", sortable: true, column: "reactions" },
              { title: "Comments", sortable: true, column: "comments" },
              { title: "Shares", sortable: true, column: "shares" },
              { title: "Impressions", sortable: true, column: "impressions" },
              { title: "Engagements", sortable: true, column: "engagement" },
              { title: "Eng. Rate", sortable: false },
              { title: "Action" },
            ].map(({ title, sortable, column }, index) => (
              <th
                key={index}
                className={sortable ? "cursor-pointer" : ""}
                onClick={
                  sortable
                    ? () => {
                        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                        setSortColumn(column);
                      }
                    : undefined
                }
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedPosts.length > 0 ? (
            sortedPosts.map((post, index) => (
              <tr
                key={post.postId}
                onClick={() =>
                  navigate("/postAnalytics", {
                    state: { postId: post.postId },
                  })
                }
                className="cursor-pointer"
              >
              <td style={{ textAlign: "center" }}>
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <img
      src={userData.linkedinProfile || "/images/pp.jpeg"}
      className="rounded-circle w-6"
      alt="User Profile"
    />
  </div>
</td>

                <td style={{ textAlign: 'left' }}>
                  {post.postContent.length > 30
                    ? `${post.postContent.slice(0, 30)}...`
                    : post.postContent}
                </td>
                <td>{formatDate(post.postCreatedAt)}</td>
                <td >{post.reactions}</td>
                <td>{post.comments}</td>
                <td>{post.reposts}</td>
                <td>{post.impressions}</td>
                <td>{post.engagements}</td>
                <td>{calculateEngagementRate(post.engagements, post.impressions)}%</td>
                <td>
                  <a
                    href={post.linkedinPostUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src="/images/linkedInIcon.svg"
                      className="cursor-pointer max-md:min-w-[20px] min-h-[34px]"
                      alt="LinkedIn"
                    />
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center py-4">
                No posts found in the selected date range
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;