import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { BASE_URL } from '../../api/API';

const HashtagAnalyticsTable = () => {
    const { userId, dateRange } = useSelector((state) => state.analytics);
    const { isDark } = useSelector((state) => state.common);

    const [analyticsData, setAnalyticsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
            const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);

            try {
                const response = await fetch(
                    `${BASE_URL}post-analytics/user/${userId}/get-hashtags?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
                );
                const data = await response.json();

                // Calculate Engagement Rate for each entry
                const dataWithEngagementRate = data.map(item => {
                    const engagementRate = item.totalImpressions > 0
                        ? (item.totalEngagements / item.totalImpressions) * 100
                        : 0;  // If no impressions, set engagement rate to 0
                    return { ...item, engagementRate };
                });

                setAnalyticsData(dataWithEngagementRate);
                setFilteredData(dataWithEngagementRate);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [userId, dateRange]);

    // Handle search input
    useEffect(() => {
        const filtered = analyticsData.filter((item) =>
            item.hashtag.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, analyticsData]);

    // Handle sorting
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
        const sortedData = [...filteredData].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });
        setSortConfig({ key, direction });
        setFilteredData(sortedData);
    };

    // Pagination logic
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="container">
        <div className="flex justify-end my-3">
  <input
    type="text"
    className={`p-3 w-64 ${isDark ? 'bg-transparent text-black border-gray-300' : 'bg-transparent text-white border-gray-600'} rounded-md`}
    placeholder="Search by hashtag..."
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    style={{
      borderRadius: "6px",
      background: "transparent",
      color: isDark ? "black" : "white",
      borderColor: isDark ? "#444444" : "#d1d1d1", // Adjust border color as needed
    }}
  />
</div>

            <table
                className={`table table-hover ${!isDark ? "table-dark text-white" : "table-light text-dark"}`}
                style={{
                    textAlign: "center",
                    borderRadius: "10px",
                    overflow: "hidden",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                <thead className="sticky-top bg-secondary text-white">
                    <tr>
                        <th onClick={() => handleSort("hashtag")} style={{ cursor: "pointer" }}>
                            Hashtag {sortConfig.key === "hashtag" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("timesUsed")} style={{ cursor: "pointer" }}>
                            Times Used {sortConfig.key === "timesUsed" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("totalLikes")} style={{ cursor: "pointer" }}>
                            Total Likes {sortConfig.key === "totalLikes" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("totalComments")} style={{ cursor: "pointer" }}>
                            Total Comments {sortConfig.key === "totalComments" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("totalImpressions")} style={{ cursor: "pointer" }}>
                            Impressions {sortConfig.key === "totalImpressions" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("totalEngagements")} style={{ cursor: "pointer" }}>
                            Engagements {sortConfig.key === "totalEngagements" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("engagementRate")} style={{ cursor: "pointer" }}>
                            Engagement Rate {sortConfig.key === "engagementRate" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.length > 0 ? (
                        paginatedData.map((item, index) => (
                            <tr
                                key={index}
                                style={{
                                    cursor: "pointer",
                                    transition: "background 0.3s",
                                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "",
                                    hover: { background: "#f5f5f5" },
                                }}
                            >
                                <td>{item.hashtag}</td>
                                <td>{item.timesUsed}</td>
                                <td>{item.totalLikes}</td>
                                <td>{item.totalComments}</td>
                                <td>{item.totalImpressions}</td>
                                <td>{item.totalEngagements}</td>
                                <td>{item.engagementRate.toFixed(2)}%</td> {/* Display Engagement Rate */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center py-4">
                                <p>No data found in the selected date range or search query.</p>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="d-flex justify-content-between my-3">
                <button
                    className="btn btn-secondary gradient-button-new"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    className="btn btn-secondary gradient-button-new"
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default HashtagAnalyticsTable;