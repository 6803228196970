import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
// import './IndustryDistributionChart.css'; 

// Define color palettes with gradient stops for slices
const GRADIENT_COLORS = [
  ['#007bff', '#6610f2'],
  ['#0056b3', '#4e0bc2'],
  ['#004085', '#390d8a'],
  ['#003366', '#300774'],
  ['#1a237e', '#5c6bc0'], // Indigo Gradient
  ['#3f51b5', '#283593'], // Deep Blue Gradient
  ['#34495e', '#2c3e50'], // Midnight Blue Gradient
  ['#212529', '#343a40'], // Dark Gray Gradient
  ['#6a1b9a', '#8e24aa'], // Purple Gradient
];

const IndustryDistributionChart = () => {
  // const { industryDistribution } = useSelector((state) => state.analytics.perPostAnalytics);
  const { industryDistribution = {} } = useSelector((state) => state.analytics.perPostAnalytics || {});

  const [data, setData] = useState([]);
  const { isDark } = useSelector((state) => state.common);

  useEffect(() => {
    if (industryDistribution && Object.keys(industryDistribution).length > 0) {
      // Format the data into an array suitable for the pie chart
      const formattedData = Object.keys(industryDistribution).map((key, index) => ({
        name: key,
        value: parseFloat(industryDistribution[key]),
        fill: `url(#gradient-${index % GRADIENT_COLORS.length})`,
      }));
      setData(formattedData);
    } else {
      setData([]); // If no data, set data as an empty array
    }
  }, [industryDistribution]);

  const renderCustomizedLabel = ({ percent, name }) => {
    return `${name} (${(percent * 100).toFixed(0)}%)`;
  };

  return (
    <div className="flex flex-col items-center gap-1">
   <div className="mb-4 w-full">
    <p className={`text-xl font-semibold ${!isDark ? "text-white" : "text-black"}`}>
    Industry Distribution
        </p>
  </div>
      <div className={`chart-box shadow-md p-4 w-full ${!isDark ? 'bg-[#2D2D2D] text-white' : 'bg-gray text-black'}`} style={{ borderRadius: '8px' }}>
        {data.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <defs>
                {/* Define gradients for each slice based on index */}
                {GRADIENT_COLORS.map((color, index) => (
                  <linearGradient key={index} id={`gradient-${index}`} x1="0" y1="0" x2="1" y2="1">
                    <stop offset="0%" stopColor={color[0]} />
                    <stop offset="100%" stopColor={color[1]} />
                  </linearGradient>
                ))}
              </defs>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              {/* <Tooltip /> */}
              <Legend
                verticalAlign="middle"
                align="left"
                layout="vertical"
                wrapperStyle={{
                  padding: '10px',
                  position: 'absolute',
                  left: '0',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: '14px',
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <div className="text-center text-lg text-gray-500">No data available</div> // Fallback UI when there's no data
        )}
      </div>
    </div>
  );
};

export default IndustryDistributionChart;