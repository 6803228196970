import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Eye, EyeOff, LogIn } from 'lucide-react';
import axios from 'axios';
import { setToken } from '../../store/features/user/authSlice';
import { fetchUserInfo } from '../../store/features/user/userSlice';
import { BASE_URL, IMAGE_URL } from '../../api/API';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}userprofile/login`,
        { email, password },
        { withCredentials: true }
      );

      if (response.data.authenticated) {
        dispatch(setToken(response.data.token));
        dispatch(fetchUserInfo(response.data.token));
        window.location.href = response.data.redirectUrl || '/dashboard';
      } else {
        setError('Authentication failed');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred during login');
        if (error.response.status === 401) setPassword('');
      } else {
        setError('Network error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-800 to-gray-300">
      <div className="w-full max-w-md p-8 space-y-6 bg-white bg-opacity-80 rounded-2xl shadow-2xl backdrop-blur-lg backdrop-filter">
        <div className="text-center">
        <div className="flex justify-center items-center">
          <img 
            src={`${IMAGE_URL}/images/logo.svg`} 
            alt="logo" 
            className="w-36 h-auto p-2 mb-2"
          />
        </div>
        {/* <h3>An AI-fueled Professional growth awaits you!</h3> */}
          <h2 className="text-3xl font-extrabold text-gray-800 tracking-tight">
            Welcome Back
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Sign in to continue to your account
          </p>
        </div>

        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label 
              htmlFor="email" 
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <input
              id="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
              placeholder="you@example.com"
            />
          </div>

          <div>
            <label 
              htmlFor="password" 
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                placeholder="Enter your password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-blue-600"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>

          {error && (
            <div className="p-3 text-sm text-red-700 bg-red-100 rounded-md">
              {error}
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300 disabled:opacity-50"
          >
            {isLoading ? (
              <span className="animate-pulse">Signing In...</span>
            ) : (
              <span className="flex items-center">
                <LogIn className="mr-2" size={20} /> Sign In
              </span>
            )}
          </button>
        </form>

        <div className="text-center space-y-4">
          <a 
            href="/forget-password"
            className="text-sm text-blue-600 hover:text-blue-800 hover:underline transition-colors"
          >
            Forgot your password?
          </a>

          <p className="text-sm text-gray-600">
            Don't have an account?{' '}
            <a 
              href="/register" 
              className="text-blue-600 hover:text-blue-800 hover:underline font-semibold transition-colors"
            >
              Sign Up
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;