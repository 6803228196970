import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL, IMAGE_URL } from '../../api/API';
import './ResetPassword.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {      
      const response = await axios.post(`${BASE_URL}userprofile/resetPassword`, {
        newPassword: password
      }, { withCredentials: true });

      console.log(response.data);

      setSuccess(true);

      setTimeout(() => {
        window.location.href = '/dashboard'; // Redirect to the desired path after a delay
      }, 3000); // 3 seconds delay
    } catch (error) {
      setError('Failed to reset password. Please try again.');
      console.error('Reset password error:', error);
    }
  };

  return (
    <div className='reset-password-container'>
      <img 
        src={`${IMAGE_URL}/images/logo.svg`}  
        className='max-md:w-[200px] mb-5 w-[300px]' 
        alt="Logo" 
      />
      <h2>Reset Password</h2>
      {success ? (
        <div className="alert alert-success">
          Password reset successfully. Redirecting to dashboard...
        </div>
      ) : (
        <form className='max-md:w-[300px]' onSubmit={handleResetPassword}>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <button 
            type="submit" 
            className="btn btn-primary grediant-button reset-button max-md:w-[300px]"
          >
            Reset Password
          </button>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;