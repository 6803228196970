import { REHYDRATE } from "redux-persist";

const initialState = {
  totalLikes: "",
  totalComments: "",
  interactions: "",
  firstname: "",
  lastname: "",
  currentPosition: "",
  currentCompanyName: "",
  linkedinProfile: "",
  location: "",
  reaction: "",
  dailyPost: "",
  post: "",
  comments: "",
  impression: "",
  likesByCompany: "",
  likesByRegion: "",
  likesByOccupation: "",
  commentsByCompany: "",
  commentsByRegion: "",
  commentsByOccupation: "",
  team: [],
  userId: "",
  postPost: "",
  summary: "",
  followersGraph: "",
  engagement: "",
  dateRange: {
    startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
    endDate: new Date(),
  }
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOTAL_LC": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          totalComments: action.payload.data.totalCommentsOnAllPosts,
          totalLikes: action.payload.data.totalLikesOnAllPosts,
          interactions: action.payload.data.interactions,
        };
      } else {
        return {
          ...state,
          totalComments: 0,
          totalLikes: 0,
          interactions: [],
        };
      }
    }
    case "PROFILE": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          firstname: action.payload.data.firstname,
          lastname: action.payload.data.lastname,
          currentPosition: action.payload.data.currentPosition,
          currentCompanyName: action.payload.data.currentCompanyName,
          linkedinProfile: action.payload.data.linkedinProfile,
          location: action.payload.data.location,
        };
      }
    }
    case "REACTION": {
      if (action.payload.data) {
        return {
          ...state,
          reaction: action.payload.data,
        };
      }
    }
    case "POST_TRACKER": {
      if (action.payload.data) {
        return {
          ...state,
          dailyPost: action.payload.data,
        };
      } else {
        return {
          ...state,
          dailyPost: {},
        };
      }
    }
    case "ALL_POST": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          post: action.payload.data,
        };
      }
    }
    case "ALL_COMMENTS": {
      if (action.payload.data) {
        return {
          ...state,
          comments: action.payload.data,
        };
      }
    }
    case "IMPRESSION": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          impression: action.payload.data,
        };
      }
    }
    case "POST_ANALYTICS": {
      console.log("action.payload.data");
      console.log(action.payload.data);
      console.log("action.payload.data");
    
      if (action.payload.data) {
        return {
          ...state,
          perPostAnalytics: {
            likesByCompany: action.payload.data.likesByCompany,
            likesByRegion: action.payload.data.likesByRegion,
            likesByOccupation: action.payload.data.likesByOccupation,
            commentsByCompany: action.payload.data.commentsByCompany,
            commentsByRegion: action.payload.data.commentsByRegion,
            commentsByOccupation: action.payload.data.commentsByOccupation,
            postPost: action.payload.data.post,
            commentsByIndustry: action.payload.data.commentsByIndustry,
            likesByIndustry: action.payload.data.likesByIndustry,
            industryDistribution: action.payload.data.industryDistribution,
            companySizeDistribution: action.payload.data.companySizeDistribution,
            performance: action.payload.data.performance,
            reactionSummaries: action.payload.data.reactionSummaries,
            reactionTypeCounts: action.payload.data.reactionTypeCounts,
            totalComments: action.payload.data.totalComments,
            totalLikes: action.payload.data.totalLikes,
            postId: action.payload.data.postId,
          },
        };
      }
      return state; // Returning state if no action.payload.data exists
    }
    
    case "TEAM_MEMBERS": {
      console.log(action.payload.data);
      if (action.payload && action.payload.data) {
        return {
          ...state,
          team: action.payload.data,
        };
      }
      return state
    }
    case "SET_USER_ID": {
      console.log(action.payload);
      if (action.payload) {
        return {
          ...state,
          userId: action.payload,
        };
      }
    }
    case 'SET_POST_ANALYTICS_SUMMARY':
      return { ...state, postAnalyticsSummary: action.payload }
      case "SET_DATE_RANGE":
        return {
          ...state,
          dateRange: action.payload
        }  
        case "SET_ANALYTICS_DATA": {
          console.log('action.payload.summary:', action.payload.summary);
          let summaryObject = {};
      
          if (Array.isArray(action.payload.summary)) {
              summaryObject = action.payload.summary.reduce((acc, item) => {
                  acc[item.label] = item.number;
                  return acc;
              }, {});
          } else if (typeof action.payload.summary === 'object') {
              summaryObject = { ...action.payload.summary };
          }
      
          return {
              ...state,
              summary: action.payload.summary,
              followersGraph: action.payload.followersGraph,
              posts: action.payload.posts,
              engagement: action.payload.engagement,
              dailyAnalytics: action.payload.dailyAnalytics,
              totalLikes: summaryObject.totalLikes || state.totalLikes,
              totalComments: summaryObject.totalComments || state.totalComments,
              totalPosts: summaryObject.postCount || state.totalPosts,
          };
      }      
    case REHYDRATE:
      if (action.payload && action.payload.analytics) {
        return {
          ...state,
          ...action.payload.analytics,
        };
      }
      return state;

    default:
      return state;
  }
};

export default analyticsReducer;
